/* eslint-disable */
import '@petrus/ui-library/styles.css';
import 'styles.css';
import React from 'react';
const MyApp = ({
  Component,
  pageProps
}: any) => {
  return <Component {...pageProps} />;
};
export default MyApp;